define('frontend-app/pods/application/controller', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		session: Ember.inject.service('session'),

		navigation_visible: false,

		windoc: Ember.inject.service(),

		currentRoute: Ember.computed("currentRouteName", function () {
			return this.get("currentRouteName").split(".").join("-");
		}),

		actions: {
			logout: function logout() {
				var self = this;
				self.get('session').invalidate();
			},
			toggleNav: function toggleNav() {
				this.toggleProperty("navigation_visible");
			},
			test: function test() {
				fetch('/api/account', { credentials: 'same-origin' }).then(function (response) {
					console.log(response.text());
				});
			}
		}
	});
});