define('frontend-app/pods/news-and-updates/index/route', ['exports', 'frontend-app/config/environment', 'ember-fetch/ajax'], function (exports, _environment, _ajax) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		infinity: Ember.inject.service(),

		model: function model() {
			return Ember.RSVP.Promise.all([this.infinity.model('news', { perPage: 5, startingPage: 1, modelPath: 'controller.articles' }), (0, _ajax.default)(_environment.default.base_url + '/api/data/news-tags')]).then(function (data) {
				return {
					articles: data[0],
					tags: data[1]
				};
			});
		},
		setupController: function setupController(controller, model) {
			this._super.apply(this, arguments);
			controller.set('articles', model.articles);
			controller.set('tags', model.tags);
		}
	});
});