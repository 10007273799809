define('frontend-app/adapters/news', ['exports', 'ember-data', 'frontend-app/config/environment'], function (exports, _emberData, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.RESTAdapter.extend({
		namespace: 'api',
		host: _environment.default.base_url,
		shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
			return false;
		}
	});
});