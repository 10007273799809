define('frontend-app/pods/components/ember-chimp/component', ['exports', 'ember-chimp/components/ember-chimp', 'frontend-app/config/environment', 'fetch-jsonp'], function (exports, _emberChimp, _environment, _fetchJsonp) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberChimp.default.extend({

		responses: {
			success: 'We have successfully signed you up for our newsletter. Stay tuned for updates.',
			error: 'Oops, something went wrong.  Please try again.',
			invalidError: 'Please enter a valid email address.',
			attemptsError: 'Please try again in about 5 minutes.'
		},

		makeRequest: function makeRequest(formAction) {
			console.log(formAction.substr(0, formAction.length - 4), this._buildData());
			var data = this._buildData();
			return (0, _fetchJsonp.default)(formAction.substr(0, formAction.length - 4) + "&EMAIL=" + data.EMAIL + "&FNAME=" + data.FNAME + "&LNAME=" + data.LNAME, { jsonpCallback: 'c' }).then(function (response) {
				return response.json();
			}).catch(function (err) {
				return err;
			});
		}
	});
});