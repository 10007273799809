define('frontend-app/pods/partners/index/controller', ['exports', 'ember-fetch/ajax', 'frontend-app/config/environment'], function (exports, _ajax, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		currentFilter: '',

		filterResults: Ember.computed('model', 'currentFilter', function () {
			var self = this;
			var filtered = [];
			var currentFilter = this.get('currentFilter');
			filtered = this.get('model');

			if (currentFilter != '') {
				filtered = filtered.filter(function (item) {
					console.log(item.value);
					return item.value.company_type == currentFilter;
				});
			}

			return filtered;
		}),

		sortDefinition: ["value.tier", "value.name"],

		sortedResults: Ember.computed.sort('filterResults', 'sortDefinition'),

		actions: {
			toggleFilter: function toggleFilter(which) {
				if (this.get('currentFilter') == which) {
					this.set('currentFilter', '');
				} else {
					this.set('currentFilter', which);
				}
			}
		}
	});
});