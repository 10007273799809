define('frontend-app/pods/partners/view/route', ['exports', 'ember-fetch/ajax', 'frontend-app/config/environment'], function (exports, _ajax, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model: function model(params) {

			this.set('params', params);

			return (0, _ajax.default)(_environment.default.base_url + '/api/data/member/' + params.slug).then(function (company) {

				return Ember.RSVP.Promise.all([(0, _ajax.default)(_environment.default.base_url + '/api/data/member-news/' + company._id), (0, _ajax.default)(_environment.default.base_url + '/api/data/member-resources/' + company._id)]).then(function (data) {
					return {
						member: company,
						articles: data[0].rows.reverse(),
						resources: data[1],
						url: _environment.default.base_url + '/partners/' + params.slug
					};
				});
			}).catch(function (err) {
				throw "not-found";
			});
		},


		afterModel: function afterModel(model) {
			var image = _environment.default.base_url + "";
			if (model.member.logo) {
				image = _environment.default.base_url + "/download/" + model.member._id + '/' + encodeURIComponent(model.member.logo);
			}
			this.setHeadTags(model, image);
			this.setTitleToken(model);
		},

		setTitleToken: function setTitleToken(model) {
			this.set('titleToken', model.member.name);
		},

		setHeadTags: function setHeadTags(model, image) {
			console.log(model);
			var headTags = [{
				type: 'meta',
				attrs: {
					property: 'og:title',
					content: model.member.name
				}
			}, {
				type: 'meta',
				attrs: {
					property: 'og:type',
					content: 'website'
				}
			}, {
				type: 'meta',
				attrs: {
					property: 'og:description',
					content: "The Fundy Ocean Research Centre for Energy (FORCE) is Canada's leading research centre for in-stream tidal energy, located in the Bay of Fundy, Nova Scotia."
				}
			}, {
				type: 'meta',
				attrs: {
					property: 'og:image',
					content: image
				}
			}, {
				type: 'meta',
				attrs: {
					property: 'og:url',
					content: _environment.default.base_url + '/partners/' + this.get('params').slug
				}
			}];

			this.set('headTags', headTags);
		}
	});
});