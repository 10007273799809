define('frontend-app/pods/partners/view/controller', ['exports', 'frontend-app/config/environment', 'ember-fetch/ajax'], function (exports, _environment, _ajax) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		members: Ember.inject.service(),

		imgSrc: "",

		isShowingModal: false,

		attachmentList: Ember.computed('model.member._attachments', function () {
			var self = this;
			if (!this.get('model.member._attachments')) return [];

			return Object.keys(this.get('model.member._attachments') || []).map(function (item) {
				return {
					filename: item,
					length: self.get('model.member._attachments')[item]['length'],
					image: self.get('model.member._attachments')[item]['content_type'].indexOf('image') != -1 ? true : false
				};
			});
		}),

		images: Ember.computed.filter('attachmentList', function (item) {
			if (this.get('model.member.logo') == item.filename) return false;else return item.image;
		}),
		files: Ember.computed.filter('attachmentList', function (item) {
			return !item.image;
		}),

		actions: {

			toggleModal: function toggleModal(memberId, imgName) {
				this.set("imgSrc", _environment.default.base_url + '/resources/' + memberId + '/' + imgName);
				this.toggleProperty('isShowingModal');
			}
		}

	});
});