define('frontend-app/pods/news-and-updates/index/controller', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		infinity: Ember.inject.service(),

		members: Ember.inject.service(),
		currentMode: 'all',
		currentTag: '',
		moreTags: false,

		limitedTags: Ember.computed('unique_tags', function () {
			return this.get('unique_tags').slice(0, 21);
		}),

		currentListing: Ember.computed('articles.[]', 'currentTag', function () {

			var articles = this.get('articles');
			var self = this;
			if (this.get('currentTag')) {
				articles = articles.filter(function (item) {
					return item.get('doc.force_tags').indexOf(self.get('currentTag')) != -1;
				});
			}
			return articles;
		}),

		unique_tags: Ember.computed('tags.[]', function () {
			var tags = this.get('tags');
			var uniqueTags = Ember.A();
			tags.forEach(function (item) {
				if (item.name != "News" && item.name != "Updates" && item.name != "Opportunities") uniqueTags.push(item.name);
			});
			return uniqueTags.uniq();
		}),

		actions: {
			changeMode: function changeMode(which) {
				this.set('currentMode', which);
				this.infinity.infinityLoad(this.get('model.articles'));
			},
			toggleTag: function toggleTag(which) {
				if (which == this.get('currentTag')) this.set('currentTag', '');else if (which == "All News and Updates") this.set('currentTag', '');else this.set('currentTag', which);
				this.infinity.infinityLoad(this.get('model.articles'));
			},
			toggleMoreTags: function toggleMoreTags() {
				this.toggleProperty('moreTags');
			}
		}
	});
});