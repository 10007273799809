define('frontend-app/components/owl-carousel2', ['exports', 'ember-cli-owl-carousel2/components/owl-carousel2'], function (exports, _owlCarousel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _owlCarousel.default;
    }
  });
});