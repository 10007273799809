define('frontend-app/instance-initializers/register-component', ['exports', 'ember-leaflet-marker-cluster/instance-initializers/register-component'], function (exports, _registerComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _registerComponent.default;
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function () {
      return _registerComponent.initialize;
    }
  });
});