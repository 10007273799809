define('frontend-app/pods/document-collection/index/controller', ['exports', 'ember-fetch/ajax', 'elasticlunr', 'frontend-app/config/environment'], function (exports, _ajax, _elasticlunr, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _defineProperty(obj, key, value) {
		if (key in obj) {
			Object.defineProperty(obj, key, {
				value: value,
				enumerable: true,
				configurable: true,
				writable: true
			});
		} else {
			obj[key] = value;
		}

		return obj;
	}

	exports.default = Ember.Controller.extend({

		members: Ember.inject.service(),

		moreTags: false,

		queryParams: _defineProperty({}, 'searchState.filter_tag', 'tag'),

		sortedDocuments: Ember.computed.sort('model', 'sortDefinition'),
		sortBy: 'date',
		sortDefinition: Ember.computed("sortBy", function () {
			if (this.get("sortBy") == "title") return ["value.title:asc"];else if (this.get("sortBy") == "date") return ["value.date:desc"];
		}),

		includedYears: Ember.computed('searchResults', function () {
			var years = [];
			var results = this.get('searchResults');
			results.forEach(function (current_resource) {
				var year = current_resource.value.date.split('-')[0];
				if (!years.includes(year)) {
					years.push(year);
				}
			});
			return years.sort().reverse();
		}),

		searchResults: Ember.computed('model', 'sortBy', 'searchState.mode', 'searchState.filter_tag.@each', 'searchState.filter_keyword', 'searchState.filter_year', function () {
			var self = this;
			var filtered = [];

			filtered = this.get('sortedDocuments');

			// Before each step below, scan filtered and compile list of unique items
			// then, remove any filter IDs that are not present in that list from searchState.
			// This will prevent an existing filter from cancelling out any search results

			var temp = [];

			if (this.get('searchState.filter_tag').length) {
				filtered.forEach(function (current_resource) {
					var include_resource = true;
					self.get('searchState.filter_tag').forEach(function (tag) {
						if (current_resource.value.force_tags.indexOf(tag) == -1) include_resource = false;
					});
					if (include_resource) temp.push(current_resource);
				});
				filtered = temp;
				console.log(filtered);
			};

			if (this.get('searchState.mode') == 'keyword') {
				var keyword_ids = Ember.A(this.search(this.get('searchState.filter_keyword')));
				filtered = filtered.filter(function (resource) {
					return keyword_ids.includes(resource.id);
				});
			}

			if (this.get('searchState.filter_year')) {
				filtered = filtered.filter(function (resource) {
					return resource.value.date.split('-')[0] == self.get('searchState.filter_year');
				});
			}

			return filtered;
		}),

		/*
  limitedTags: Ember.computed('unique_tags', function() {
  	return this.get('unique_tags').slice(0, 31);
  }),
  */

		unique_tags: Ember.computed('searchResults', function () {
			var tags = {};
			this.get('searchResults').forEach(function (item) {
				item.value.force_tags.forEach(function (tag) {
					if (tags[tag]) {
						tags[tag]++;
					} else {
						tags[tag] = 1;
					}
				});
			});
			var sortable = [];
			for (var tag in tags) {
				sortable.push([tag, tags[tag]]);
			}
			sortable.sort(function (a, b) {
				return b[1] - a[1];
			});
			return sortable;
		}),

		tags_recommended: Ember.computed('unique_tags.@each', function () {
			var recommended = this.get('unique_tags').filter(function (tag) {
				return tag[0] == 'Advisory Committee' || tag[0] == 'Board Meeting' || tag[0] == 'Environmental Assessment' || tag[0] == 'Monitoring Reports' || tag[0] == 'Photo & Video' || tag[0] == 'Third Party Links';
			});
			return recommended;
		}),

		tags_popular: Ember.computed('unique_tags.@each', function () {
			var popular = this.get('unique_tags').filter(function (tag) {
				return tag[0] != 'Advisory Committee' && tag[0] != 'Board Meeting' && tag[0] != 'Environmental Assessment' && tag[0] != 'Monitoring Reports' && tag[0] != 'Photo & Video' && tag[0] != 'Third Party Links';
			});
			return popular;
		}),

		unique_tags_names: Ember.computed('unique_tags.@each', function () {
			var tags = this.get('unique_tags');
			var names = [];
			tags.forEach(function (item) {
				names.push(item[0]);
			});
			return names;
		}),

		searchState: Ember.Object.create({
			mode: 'browse',
			filter_tag: [],
			filter_keyword: '',
			filter_year: ''
		}),

		inputKeyword: '',

		onFilterTextChange: Ember.observer('inputKeyword', function () {
			// apply a delay so every keypress doesn't start a computation
			Ember.run.debounce(this, this.applyKeywordFilter, 500);
		}),

		applyKeywordFilter: function applyKeywordFilter() {
			if (this.get('inputKeyword') == '') {
				this.set('searchState.mode', 'browse');
				this.set('searchState.filter_keyword', '');
				return;
			}
			this.set("searchState.mode", "keyword");
			this.set('searchState.filter_keyword', this.get('inputKeyword'));
		},

		index: (0, _elasticlunr.default)(function () {
			this.addField('title');
			this.addField('description');
			this.addField('tags');
			this.addField('companies');
			this.setRef('id');
		}),

		add_search_item: function add_search_item(row) {
			var member_lookup = this.get('members.member_lookup');
			var item = row.value;
			var entry = {
				id: row.id,
				title: item.title,
				description: item.description,
				tags: item.force_tags.join(" "),
				companies: item.tagged_companies.map(function (company) {
					return member_lookup[company];
				}).join(" ")
			};
			this.get('index').addDoc(entry);
		},


		onModelLoad: Ember.observer('model', function () {
			var self = this;
			this.get('model').forEach(function (row) {
				//console.log(row);
				self.add_search_item(row);
			});
		}),

		search: function search(query) {
			return this.get('index').search(query, { expand: true }).mapBy('ref');
		},


		actions: {

			//action to prevent the filter search box from refreshing the page when you press enter
			prevent: function prevent(e) {
				e.preventDefault();
				return false;
			},
			setSorting: function setSorting(which) {
				this.set("sortBy", which);
			},
			toggleMoreTags: function toggleMoreTags() {
				this.toggleProperty('moreTags');
			},
			toggleTag: function toggleTag(which) {
				if (this.get('searchState.filter_tag').includes(which)) {
					this.get('searchState.filter_tag').removeObject(which);
				} else {
					this.get('searchState.filter_tag').pushObject(which);
				}
			},
			toggleTagMobile: function toggleTagMobile(which) {
				if (which == '') {
					this.set('searchState.filter_tag', []);
				} else if (this.get('searchState.filter_tag').includes(which)) {
					this.get('searchState.filter_tag').removeObject(which);
				} else {
					this.set('searchState.filter_tag', []);
					this.get('searchState.filter_tag').pushObject(which);
				}
			},
			toggleYear: function toggleYear(which) {
				if (which == '') {
					this.set('searchState.filter_year', '');
				} else if (this.get('searchState.filter_year') == which) {
					this.set('searchState.filter_year', '');
				} else {
					this.set('searchState.filter_year', which);
				}
			},
			reset: function reset() {
				this.set('inputKeyword', '');
				this.set('searchState', Ember.Object.create({
					mode: 'browse',
					filter_tag: [],
					filter_keyword: '',
					filter_year: ''
				}));
			}
		}
	});
});