define('frontend-app/components/twitter-feed', ['exports', 'ember-cli-twitter-feed/components/twitter-feed'], function (exports, _twitterFeed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _twitterFeed.default;
    }
  });
});