define('frontend-app/services/members', ['exports', 'frontend-app/config/environment', 'ember-fetch/ajax'], function (exports, _environment, _ajax) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({

		all_members: Ember.A(),

		member_lookup: Ember.computed("all_members", function () {
			return this.get('all_members').reduce(function (prev, current, list) {
				prev[current.id] = current.value.name;
				return prev;
			}, {});
		}),
		member_logo_lookup: Ember.computed("all_members", function () {
			return this.get('all_members').reduce(function (prev, current, list) {
				prev[current.id] = current.value.logo;
				return prev;
			}, {});
		}),

		member_slug_lookup: Ember.computed("all_members", function () {
			return this.get('all_members').reduce(function (prev, current, list) {
				prev[current.id] = current.value.slug;
				return prev;
			}, {});
		}),

		init: function init() {
			console.log('hello');
			this._super.apply(this, arguments);
			var self = this;
			this.getData('members');
		},
		getData: function getData(which) {
			// can be members, capabilities or tags
			var self = this;

			if (which == 'members') {
				if (this.get('all_members.length') === 0) {

					return (0, _ajax.default)(_environment.default.base_url + '/api/data/member-fulltext').then(function (data) {
						self.set('all_members', data.rows);
						return data.rows;
					});
				} else {
					return new Ember.RSVP.Promise(function (resolve, reject) {
						resolve(self.get('all_members'));
					});
				}
			}
		},
		load: function load() {
			var self = this;
			return new Ember.RSVP.Promise(function (resolve, reject) {
				return self.getData('members').then(function (data) {
					resolve(self.get('all_members'));
				});
			});
		}
	});
});