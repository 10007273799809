define('frontend-app/pods/news-and-updates/view/route', ['exports', 'frontend-app/config/environment', 'ember-fetch/ajax'], function (exports, _environment, _ajax) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model: function model(params) {
			this.set('params', params);

			return (0, _ajax.default)(_environment.default.base_url + '/api/news/' + params.date + '/' + params.slug).then(function (article) {
				return Ember.RSVP.Promise.all([(0, _ajax.default)(_environment.default.base_url + '/api/data/news-resources/' + article._id), (0, _ajax.default)(_environment.default.base_url + '/api/news?per_page=3&page=1')]).then(function (data) {
					return {
						article: article,
						resources: data[0],
						aside: data[1].news,
						url: _environment.default.base_url + '/news-and-updates/' + params.date + '/' + params.slug
					};
				});
			}).catch(function (err) {
				throw "not-found";
			});
		},


		afterModel: function afterModel(model) {
			var image = _environment.default.base_url + "/assets/images/fundy-ocean-research-centre-for-energy.jpg";
			if (model.article.cover_image) {
				image = _environment.default.base_url + '/download/' + model.article._id + '/' + encodeURIComponent(model.article.cover_image);
			} else if (model.article.image_url) {
				image = model.article.image_url;
			}
			this.setHeadTags(model, image);
			this.setTitleToken(model);
		},

		setTitleToken: function setTitleToken(model) {
			this.set('titleToken', model.article.title);
		},

		setHeadTags: function setHeadTags(model, image) {
			var self = this;
			var headTags = [{
				type: 'meta',
				attrs: {
					property: 'og:title',
					content: model.article.title
				}
			}, {
				type: 'meta',
				attrs: {
					property: 'og:description',
					content: "The Fundy Ocean Research Centre for Energy (FORCE) is Canada's leading research centre for in-stream tidal energy, located in the Bay of Fundy, Nova Scotia."
				}
			}, {
				type: 'meta',
				attrs: {
					property: 'og:type',
					content: 'article'
				}
			}, {
				type: 'meta',
				attrs: {
					property: 'og:image',
					content: image
				}
			}, {
				type: 'meta',
				attrs: {
					property: 'og:url',
					content: _environment.default.base_url + '/news-and-updates/' + this.get('params').date + '/' + this.get('params').slug
				}
			}];

			this.set('headTags', headTags);
		}
	});
});