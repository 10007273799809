define('frontend-app/pods/contact-us/controller', ['exports', 'ember-fetch/ajax', 'frontend-app/config/environment'], function (exports, _ajax, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		contact: {
			name: "",
			phone_number: "",
			email: "",
			subject: "",
			message: ""
		},

		actions: {
			processEmail: function processEmail() {
				(0, _ajax.default)(_environment.default.base_url + '/api/contact', {
					credentials: 'same-origin',
					method: 'post',
					body: JSON.stringify({
						name: this.get('contact.name'),
						phone_number: this.get('contact.phone_number'),
						email: this.get('contact.email'),
						subject: this.get('contact.subject'),
						message: this.get('contact.message')
					})
				}).then(function (response) {
					console.log(response);
					if (response.error) {
						alert(response.error);
					} else if (response.success) {
						alert(response.success);
					}
				}).catch(function (err) {
					alert(err);
				});
			}
		}
	});
});