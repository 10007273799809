define('frontend-app/services/forcelive', ['exports', 'frontend-app/config/environment', 'ember-fetch/ajax'], function (exports, _environment, _ajax) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({
		init: function init() {
			this._super.apply(this, arguments);
			var self = this;
			(0, _ajax.default)(_environment.default.base_url + '/api/data/fundyforcelive').then(function (data) {
				self.set('force_data', data);
				self.load();
			});
		},

		force_data: [],

		load: function load() {
			var self = this;
			Ember.run.later(function () {
				(0, _ajax.default)(_environment.default.base_url + '/api/data/fundyforcelive').then(function (data) {
					self.set('force_data', data);
					self.load();
				});
			}, 10000);
		}
	});
});