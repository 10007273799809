define('frontend-app/pods/application/route', ['exports', 'ember-simple-auth/mixins/application-route-mixin', 'frontend-app/config/environment', 'ember-fetch/ajax'], function (exports, _applicationRouteMixin, _environment, _ajax) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_applicationRouteMixin.default, {

		fastboot: Ember.inject.service(),

		sessionAuthenticated: function sessionAuthenticated() {
			var attemptedTransition = this.get('session.attemptedTransition');
			var cookies = Ember.getOwner(this).lookup('service:cookies');
			var redirectTarget = cookies.read('ember_simple_auth-redirectTarget');

			if (attemptedTransition) {
				attemptedTransition.retry();
				this.set('session.attemptedTransition', null);
			} else if (redirectTarget) {
				this.transitionTo(redirectTarget);
				cookies.clear('ember_simple_auth-redirectTarget');
			} else {
				// unless specified, go to /account:
				console.log(this.get('controller.currentRouteName'));
				if ([/* names of routes from which you don't want to redirect after authenticating */].indexOf(this.get('controller.currentRouteName')) == -1) this.transitionTo(this.get('routeAfterAuthentication'));
			}
		},


		title: function title(tokens) {
			tokens = Ember.makeArray(tokens);
			tokens.unshift('FORCE');
			return tokens.reverse().join(' | ');
		},

		actions: {
			error: function error(_error, transition) {
				var fastboot = this.get('fastboot');

				var notFound = _error === 'not-found' || _error && _error.httpErrorResponse && _error.httpErrorResponse.status === 404;
				// routes that can't find models
				if (notFound) {
					if (fastboot.get('isFastBoot')) {
						this.set('fastboot.response.statusCode', 404);
					}
					this.intermediateTransitionTo('404', { wildcard: '404' });
				} else {
					return true;
				}
			},
			willTransition: function willTransition() {
				this.set('controller.navigation_visible', false);
			}
		}
	});
});