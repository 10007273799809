define("frontend-app/transitions", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function () {
		// Add your transitions here, like:
		//   this.transition(
		//     this.fromRoute('people.index'),
		//     this.toRoute('people.detail'),
		//     this.use('toLeft'),
		//     this.reverse('toRight')
		//   );

		this.transition(this.hasClass('liquid-partner-list'), this.use("explode", {
			matchBy: "data-id",
			use: ["fly-to", { easing: "easeOutExpo", duration: 300 }]
		}
		//{	use: ["fade", {easing: "easeOutExpo", duration: 200}] }
		));
	};
});