define('frontend-app/pods/index/route', ['exports', 'frontend-app/config/environment', 'ember-fetch/ajax'], function (exports, _environment, _ajax) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model: function model() {

			return Ember.RSVP.Promise.all([(0, _ajax.default)(_environment.default.base_url + '/api/news?per_page=3&page=1'), (0, _ajax.default)(_environment.default.base_url + '/api/data/featured-resources')]).then(function (data) {
				return {
					news: data[0].news,
					resources: data[1].rows
				};
			}).catch(function (err) {
				console.log(err);
			});
		},

		afterModel: function afterModel(model) {
			var image = _environment.default.base_url + "/assets/images/fundy-ocean-research-centre-for-energy.jpg";
			this.setHeadTags(image);
		},

		setHeadTags: function setHeadTags(image) {
			var headTags = [{
				type: 'meta',
				attrs: {
					property: 'og:title',
					content: 'Fundy Ocean Research Centre for Energy'
				}
			}, {
				type: 'meta',
				attrs: {
					property: 'og:type',
					content: 'website'
				}
			}, {
				type: 'meta',
				attrs: {
					property: 'og:description',
					content: "The Fundy Ocean Research Centre for Energy (FORCE) is Canada's leading research centre for in-stream tidal energy, located in the Bay of Fundy, Nova Scotia."
				}
			}, {
				type: 'meta',
				attrs: {
					property: 'og:image',
					content: image
				}
			}, {
				type: 'meta',
				attrs: {
					property: 'og:url',
					content: _environment.default.base_url
				}
			}];

			this.set('headTags', headTags);
		}
	});
});