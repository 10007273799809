define('frontend-app/components/facebook-feed', ['exports', 'ember-cli-facebook-feed/components/facebook-feed'], function (exports, _facebookFeed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _facebookFeed.default;
    }
  });
});