define('frontend-app/pods/learning-portal/index/controller', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		forcelive: Ember.inject.service(),

		forceData: Ember.computed('forcelive.force_data', function () {
			return this.get('forcelive.force_data');
		})
	});
});