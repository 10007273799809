define('frontend-app/pods/document-collection/view/route', ['exports', 'frontend-app/config/environment', 'ember-fetch/ajax'], function (exports, _environment, _ajax) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model: function model(params) {
			return (0, _ajax.default)(_environment.default.base_url + '/api/resources/' + params.slug).then(function (resource) {
				return {
					resource: resource,
					url: _environment.default.base_url + '/document-collection/' + params.slug
				};
			}).catch(function (err) {
				throw "not-found";
			});
		},


		afterModel: function afterModel(model) {
			var image = _environment.default.base_url + "/assets/images/fundy-ocean-research-centre-for-energy.jpg";
			this.setHeadTags(model, image);
			this.setTitleToken(model);
		},

		setTitleToken: function setTitleToken(model) {
			this.set('titleToken', model.resource.title);
		},

		setHeadTags: function setHeadTags(model, image) {
			var headTags = [{
				type: 'meta',
				attrs: {
					property: 'og:title',
					content: model.resource.name
				}
			}, {
				type: 'meta',
				attrs: {
					property: 'og:type',
					content: 'website'
				}
			}, {
				type: 'meta',
				attrs: {
					property: 'og:description',
					content: "The Fundy Ocean Research Centre for Energy (FORCE) is Canada's leading research centre for in-stream tidal energy, located in the Bay of Fundy, Nova Scotia."
				}
			}, {
				type: 'meta',
				attrs: {
					property: 'og:image',
					content: image
				}
			}, {
				type: 'meta',
				attrs: {
					property: 'og:url',
					content: _environment.default.base_url + '/document-collection/' + model.resource.slug
				}
			}];

			this.set('headTags', headTags);
		}

	});
});