define('frontend-app/pods/learning-portal/environmental-monitoring/controller', ['exports', 'frontend-app/config/environment', 'ember-fetch/ajax'], function (exports, _environment, _ajax) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		imgSrc: "",

		isShowingModal: false,

		actions: {

			toggleModal: function toggleModal(imgName) {
				this.set("imgSrc", imgName);
				this.toggleProperty('isShowingModal');
			}
		}

	});
});