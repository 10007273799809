define("frontend-app/pods/index/controller", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		forcelive: Ember.inject.service(),

		tideDirection: "",

		tideMax: "",

		tideMin: "",

		forceData: Ember.computed('forcelive.force_data', function () {
			var tideValues = this.get('forcelive.force_data').tide_full || { 'error': 'unavailable' };
			if (!tideValues.error) {
				//This is the approximate time between a min and max tide in milliseconds (6.2 hours)
				var TIDE_LENGTH = 22320000;

				if (tideValues) {
					if (tideValues[tideValues.length - 1].depth > tideValues[tideValues.length - 2].depth) {
						this.set("tideDirection", "Rising");
					} else {
						this.set("tideDirection", "Falling");
					}
				}

				var tideDirection = this.get('tideDirection');
				var now = new Date();

				if (tideDirection == 'Falling') {
					for (var i = tideValues.length; i--;) {
						var currentTide = tideValues[i - 1];
						if (parseFloat(currentTide.depth) > parseFloat(tideValues[i - 2].depth)) {
							//this is the last max
							break;
						}
					}
					var lastDate = new Date(currentTide.date);
					this.set('tideMin', lastDate.getTime() + TIDE_LENGTH);
					this.set('tideMax', lastDate.getTime());
				}

				if (tideDirection == 'Rising') {
					var i;
					for (i = tideValues.length; i--;) {
						var currentTide = tideValues[i - 1];
						if (parseFloat(currentTide.depth) < parseFloat(tideValues[i - 2].depth)) {
							//this is the last min
							break;
						}
					}
					var lastDate = new Date(currentTide.date);
					this.set('tideMin', lastDate.getTime());
					//We always must use last max in order to calculate next tides
					for (i = i; i--;) {
						var currentTide = tideValues[i - 1];
						if (parseFloat(currentTide.depth) > parseFloat(tideValues[i - 2].depth)) {
							//this is the last max
							break;
						}
					}
					lastDate = new Date(currentTide.date);
					this.set('tideMax', lastDate.getTime() + TIDE_LENGTH * 2);
				}
			}
			return this.get('forcelive.force_data');
		}),

		sortedDocuments: Ember.computed.sort('model.resources', 'sortDefinition'),
		sortDefinition: Ember.computed('model.resources', function () {
			return ["value.date:desc"];
		})
	});
});