define('frontend-app/pods/risk-assessment-program/route', ['exports', 'frontend-app/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    beforeModel: function beforeModel() /* transition */{
      window.location = _environment.default.base_url + '/document-collection/risk-assessment-program';
    }
  });
});