define('frontend-app/router', ['exports', 'frontend-app/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Router = Ember.Router.extend({
        location: _environment.default.locationType,
        rootURL: _environment.default.rootURL
    });

    Router.map(function () {
        this.route('about-us');
        this.route('news-and-updates', function () {
            this.route('view', { path: '/:date/:slug' });
        });
        this.route('partners', function () {
            this.route('view', { path: '/:slug' });
        });
        this.route('document-collection', function () {
            this.route('view', { path: '/:slug' });
        });
        this.route('learning-portal', function () {
            this.route('tides');
            this.route('tidal-energy');
            this.route('environmental-monitoring');
        });
        this.route('visit');
        this.route('contact-us');

        this.route('reset-password');
        this.route('forgot-password');
        this.route('forgot-password');
        this.route('activate-account');
        this.route('sign-in');
        this.route('account');
        this.route('404', { path: ':wildcard' });
        this.route('risk-assessment-program');
        this.route('capabilities');
    });

    exports.default = Router;
});